:root {
  --primary-color: #4CAF50;
  --primary-hover-color: #45a049;
  --background-color: #f8f9fa;
  --border-color: #e0e0e0;
  --focus-color: #e6f3ff;
  --hover-color: #f0f0f0;
  --edited-color: #e8f0fe;
  --disabled-color: #cccccc;
  --error-color: #ff0000;
  --box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  --border-radius: 4px;
}

/* Container Styles */
.spreadsheet-container {
  overflow-x: auto;
  max-width: 100%;
  margin-bottom: 20px;
}

.bulk-edit-container {
  margin-bottom: 20px;
  padding: 15px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}

/* Table Styles */
.editable-spreadsheet {
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
  box-shadow: var(--box-shadow);
  overflow-x: auto;
  overflow-y: visible;
  display: block;
  white-space: nowrap;
}

.editable-spreadsheet th,
.editable-spreadsheet td {
  border: 1px solid var(--border-color);
  padding: 0;
}

.editable-spreadsheet th,
.editable-spreadsheet .row-header,
.editable-spreadsheet .column-header {
  background-color: var(--background-color);
  font-weight: bold;
  text-align: center;
  padding: 12px;
}

/* Input Styles */
.editable-spreadsheet input,
.bulk-edit-container input {
  width: 100%;
  padding: 8px;
  border: none;
  background-color: transparent;
  font-size: 14px;
  outline: none;
  transition: background-color 0.3s;
}

.editable-spreadsheet input:focus {
  background-color: var(--focus-color);
}

.editable-spreadsheet input:hover {
  background-color: var(--hover-color);
}

.editable-spreadsheet .cell-edited {
  background-color: var(--edited-color);
}

/* Button Styles */
.bulk-edit-container button,
.delete-rows-container button,
.confirm-dialog-content button,
.manage-words-modal button,
.deletion-log-content button {
  padding: 8px 15px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.bulk-edit-container button:hover,
.delete-rows-container button:hover,
.confirm-dialog-content button:hover,
.manage-words-modal button:hover,
.deletion-log-content button:hover {
  background-color: var(--primary-hover-color);
}

.bulk-edit-container button:disabled,
.delete-rows-container button:disabled {
  background-color: var(--disabled-color);
  cursor: not-allowed;
}

/* Text Styles */
.bulk-edit-container h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.error-message {
  color: var(--error-color);
  margin-top: 10px;
}

/* Media Queries */
@media (max-width: 768px) {
  .editable-spreadsheet input {
    font-size: 12px;
    padding: 6px;
  }
}

.editable-spreadsheet-container .loading-message,
.editable-spreadsheet-container .error-message,
.editable-spreadsheet-container .no-data-message {
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-weight: bold;
}

.editable-spreadsheet-container .loading-message {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.editable-spreadsheet-container .error-message {
  background-color: #ffebee;
  color: #c62828;
}

.editable-spreadsheet-container .no-data-message {
  background-color: #fff3e0;
  color: #ef6c00;
}

/* Debug Info Styles */
.debug-info {
  background-color: var(--hover-color);
  padding: 10px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-wrap: break-word;
  width: 100%;
  box-sizing: border-box;
}

.debug-info p {
  margin-right: 20px;
  font-size: 12px;
}

@media (max-width: 600px) {
  .debug-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .debug-info p {
    margin-right: 0;
    margin-bottom: 10px;
    font-size: 10px;
  }
}

/* Delete Button Styles */
.delete-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 0.9rem;
}

.delete-button:hover {
  background-color: #c0392b;
}

/* Delete Rows Container Styles */
.delete-rows-container {
  margin: 20px 0;
}

.delete-rows-container input {
  padding: 5px;
  font-size: 1rem;
  width: 300px;
}

.delete-rows-container button {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 1rem;
  background-color: #e74c3c;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: var(--border-radius);
}

.delete-rows-container button:hover {
  background-color: #c0392b;
}

/* Confirmation Dialog Styles */
.confirm-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirm-dialog-content {
  background-color: white;
  padding: 20px;
  border-radius: var(--border-radius);
  width: 500px;
  max-height: 80%;
  overflow-y: auto;
}

.confirm-dialog-content p {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.confirm-dialog-content ul {
  list-style-type: disc;
  margin-left: 20px;
  max-height: 300px;
  overflow-y: auto;
}

.confirm-dialog-content button {
  margin: 10px 5px 0 0;
  padding: 5px 10px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: var(--border-radius);
}

.confirm-button {
  background-color: #dc3545;
  color: #fff;
}

.confirm-button:hover {
  background-color: #c82333;
}

.cancel-button {
  background-color: #6c757d;
  color: #fff;
}

.cancel-button:hover {
  background-color: #5a6268;
}

/* Deletion Log Modal Styles */
.deletion-log-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.deletion-log-content {
  background: #fff;
  padding: 20px;
  border-radius: var(--border-radius);
  max-width: 800px;
  width: 90%;
  position: relative;
}

.deletion-log-content h3 {
  margin-top: 0;
}

.deletion-log-content .close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--error-color);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: var(--border-radius);
  font-size: 1.2rem;
  line-height: 1;
}

.deletion-log-content .close-modal-button:hover {
  background-color: #c0392b;
}

.deleted-items-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.deleted-items-table th,
.deleted-items-table td {
  border: 1px solid var(--border-color);
  padding: 8px;
  text-align: left;
}

.deleted-items-table th {
  background-color: var(--background-color);
}

.restore-button {
  background-color: #2ecc71;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: var(--border-radius);
  font-size: 0.9rem;
}

.restore-button:hover {
  background-color: #27ae60;
}

/* View Deletion Log Button */
.view-deletion-log-button {
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 1rem;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: var(--border-radius);
}

.view-deletion-log-button:hover {
  background-color: #2980b9;
}

/* Manage Words Modal Styles */
.manage-words-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  max-width: 500px;
  width: 90%;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.manage-words-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.manage-words-input {
  display: flex;
  margin-bottom: 20px;
}

.manage-words-input input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.manage-words-input button {
  margin-left: 10px;
  padding: 8px 12px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.manage-words-input button:hover {
  background-color: #0056b3;
}

.manage-words-list {
  list-style: none;
  padding: 0;
}

.manage-words-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  border-bottom: 1px solid #eee;
}

.manage-words-list button {
  background: none;
  border: none;
  color: #ff4d4f;
  cursor: pointer;
}

.manage-words-list button:hover {
  color: #d9363e;
}

/* react-select Custom Styles */
.editable-spreadsheet-container .delete-rows-container .react-select__multi-value {
  background-color: #e0e0e0;
}

.editable-spreadsheet-container .delete-rows-container .react-select__multi-value__label {
  color: #333;
}

.editable-spreadsheet-container .delete-rows-container .react-select__multi-value__remove {
  color: #666;
}

.editable-spreadsheet-container .delete-rows-container .react-select__multi-value__remove:hover {
  background-color: #ccc;
  color: #000;
}

/* Additional Styles for Modal Components */

/* Confirmation Dialog Button Styles */
.confirm-dialog-content .confirm-button {
  background-color: #dc3545;
  color: #fff;
  border: none;
}

.confirm-dialog-content .confirm-button:hover {
  background-color: #c82333;
}

.confirm-dialog-content .cancel-button {
  background-color: #6c757d;
  color: #fff;
  border: none;
}

.confirm-dialog-content .cancel-button:hover {
  background-color: #5a6268;
}

/* Manage Words Modal Button Styles */
.manage-words-modal .close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Deletion Log Modal Button Styles */
.deletion-log-content .close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Restore Button Styles */
.deletion-log-content .restore-button {
  background-color: #2ecc71;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: var(--border-radius);
  font-size: 0.9rem;
}

.deletion-log-content .restore-button:hover {
  background-color: #27ae60;
}

/* One-time Added Styles */
/* Manage Words Import Section */
.manage-words-import {
  margin-top: 20px;
}

.manage-words-import textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.manage-words-import button {
  margin-top: 10px;
  padding: 8px 12px;
  border: none;
  background-color: #17a2b8;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.manage-words-import button:hover {
  background-color: #138496;
}

/* ワードを管理ボタンのスタイル */
.manage-words-button {
  background-color: #007bff; /* ボタンの背景色を青色に設定 */
  color: white;              /* ボタンの文字色を白色に設定 */
  border: none;
  padding: 8px 15px;
  border-radius: var(--border-radius);
  cursor: pointer;
}

/* ワードを管理ボタンのスタイル */
.manage-words-button {
  background-color: #007bff; /* 青色 */
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.manage-words-button:hover {
  background-color: #0056b3; /* 濃い青色 */
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* top-controls クラスを追加して、要素を横並びに配置 */
.top-controls {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* 左寄せ */
  margin-bottom: 10px;
  gap: 20px; /* 要素間の間隔を設定 */
}

/* CSVダウンロードボタンのスタイルを調整 */
.csv-download-button-container {
  margin-left: 10px;
}

.csv-download-button {
  background-color: #4caf50; /* 緑色 */
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

.csv-download-button:hover {
  background-color: #45a049;
}

/* ページネーションのスタイルを調整 */
.pagination-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pagination-controls button {
  padding: 6px 12px;
}

.pagination-controls span {
  margin: 0 5px;
}
