/* Global Styles */
.App {
  max-width: 95%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1, h2, h3 {
  color: #333;
  margin-bottom: 20px;
}

/* Input and Button Styles */
input, button, select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 5px;
}

input {
  flex-grow: 1;
}

button {
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Flex container for input sections */
.input-container, .sheet-selector {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
}

.input-container .input-field, .sheet-selector .select-field {
  flex: 1 1 auto;
  min-width: 150px; /* Ensure a minimum width for inputs */
}

.input-container .button-group, .sheet-selector .button-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.button-group button {
  margin-left: 10px; /* Add margin to the left of buttons */
}

/* Table Styles */
.table-container {
  overflow-x: auto;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Saved Spreadsheets Styles */
.saved-spreadsheets {
  margin-top: 20px;
  margin-bottom: 20px;
}

.saved-spreadsheets ul {
  list-style-type: none;
  padding: 0;
}

.saved-spreadsheets li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.saved-spreadsheets button {
  margin-right: 10px;
  background-color: #f0f0f0;
  color: #333;
}

.saved-spreadsheets button:hover {
  background-color: #e0e0e0;
}

.delete-btn {
  background-color: #ff4d4d;
  color: white;
}

.delete-btn:hover {
  background-color: #ff3333;
}

/* Utility Classes */
.error-message {
  color: red;
  margin-top: 10px;
}

/* Column Width Control */
.column-width-control {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.column-width-control label {
  font-weight: bold;
}

.column-width-control input {
  width: 60px;
}